<template>
  <v-autocomplete
    v-model="value"
    :items="filteredDebrisTypes"
    :label="field.text"
    placeholder="Wybierz kod"
    dense
    outlined
    clearable
    item-value="code"
    :item-text="getDebrisString"
    :filter="searchDebrisType"
  />
</template>

<script>
import { searchDebrisType, getDebrisString } from '../../../utils'
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  mixins: [inputMixin], // data, field, validators
  computed: {
    ...mapState({
      debrisTypes: state => state.debrisTypes.items,
    }),
    value: {
      get () {
        return get(this.data, this.field.value, '')
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value || null })
      },
    },
    filteredDebrisTypes() {
      return this.debrisTypes.filter((type) => {
        const settings = type.debrisTypesDepartments?.find(department => department.department.id === this.data.id)
        const label = this.field.value.replace('DefaultCode', '')
        return !!settings[label]
      })
    }
  },
  methods: {
    searchDebrisType,
    getDebrisString,
  }
}
</script>
